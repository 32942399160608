@tailwind base;
@tailwind components;
@tailwind utilities;

.tox-notifications-container {
  display: none;
}

.hide-scroll::-webkit-scrollbar {
  width: 0 !important;
}
.hide-scroll {
  overflow: -moz-scrollbars-none;
}
.hide-scroll {
  -ms-overflow-style: none;
}
